export const getSlug = (name, lower = false, encoded = false) => {
  let slug = name.split(" ").map(encodeURIComponent).join("+")

  if (encoded) {
    slug = name.split(" ").map(encodeURIComponent).join(encodeURIComponent("+"))
  }

  if (!lower) return slug

  return slug.toLowerCase()
}

export const getName = slug => {
  if (slug === "all" || slug === "tout") return ""
  return decodeURIComponent(slug).split("+").map(decodeURIComponent).join(" ")
}
