import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import Hero from "../property/Hero"
import Map from "../property/Map"
import Contact from "../property/Contact"
import Description from "../property/Description"

import { Gallery, PropertiesSlider } from "../../components/blocks"
import Layout from "../../components/Layout"
import Video from "../../components/Video"
import Content from "../../components/Content"

const Index = ({ data }) => {
  const imageTags = [
    {
      tagName: "meta",
      attributes: {
        property: "og:image",
        content: data.project.seoImage[0] && data.project.seoImage[0].url,
      },
    },
    {
      tagName: "meta",
      attributes: {
        name: "twitter:image",
        content: data.project.seoImage[0] && data.project.seoImage[0].url,
      },
    },
  ]

  const metaTags = {
    tags: data.project.seoMetaTags.tags
      .map(tag =>
        data.project.description &&
        tag.attributes &&
        ((tag.attributes.name && tag.attributes.name.includes("description")) ||
          (tag.attributes.property &&
            tag.attributes.property.includes("description")))
          ? {
              ...tag,
              attributes: {
                ...tag.attributes,
                content: data.project.description.substring(0, 160).concat("…"),
              },
            }
          : tag
      )
      .concat(imageTags),
  }

  return (
    <Layout transparentHeader>
      <HelmetDatoCms
        htmlAttributes={{
          lang: data.project.locale,
        }}
        seo={metaTags}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: data.project.title,
            description: data.project.description,
            url: `https://ikeys-realty.com/${data.project.locale}/${data.projectsPage.slug}/${data.project.slug}`,
            image: [data.project.seoImage[0] && data.project.seoImage[0].url],
            sku: data.project.reference,
            mpn: data.project.reference,
            brand: {
              "@type": "Organization",
              name: "iKeys Realty",
              logo: {
                "@type": "ImageObject",
                url: "https://ikeys-realty.com/ikeys-logo.png",
              },
            },
            offers:
              data.project.properties.length > 0 &&
              data.project.properties.map(property => ({
                "@type": "Offer",
                url: `https://ikeys-realty.com/${property.locale}/${property.slug}`,
                priceCurrency: property.currency.code,
                price: property.price,
                priceValidUntil: "2030-12-31",
                itemCondition: "https://schema.org/NewCondition",
                availability: ["6625332", "6625335", "6625337"].includes(
                  property.propertyStatus.originalId
                )
                  ? "https://schema.org/InStock"
                  : "https://schema.org/SoldOut",
              })),
          })}
        </script>
        {data.project._allSlugLocales.map((version, index) => (
          <link rel="alternate" hreflang={version.locale} href={`https://ikeys-realty.com/${version.locale}/${data.projectsPage._allSlugLocales[index].value}/${version.value}`} key={index} />
        ))}
      </HelmetDatoCms>
      <div className="property">
        <Hero property={data.project} />
        <Description property={data.project} search={data.search} project />
        {data.project.properties.length > 0 && (
          <PropertiesSlider data={data.project} global={data.global} />
        )}
        <Video video={data.project.video} global={data.global} />
        <Gallery data={data.project.gallery} global={data.global} />
        <Content content={data.project.content} />
        <Map property={data.project} />
        <Contact data={data} property={data.project} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProjectQuery($locale: String!, $slug: String!) {
    project: datoCmsProject(locale: { eq: $locale }, slug: { eq: $slug }) {
      ...Project
    }
    projectsPage: datoCmsProjectsPage(locale: { eq: $locale }) {
      slug
      _allSlugLocales {
        locale
        value
      }
    }
    global: datoCmsGlobal(locale: { eq: $locale }) {
      ...Global
    }
    search: datoCmsSearch(locale: { eq: $locale }) {
      ...Search
    }
  }
`

export default Index
